<template>
	<div class="full-width full-height">
		<div class="flex-auto-full-height-content">
			<div class="header p-sm">
				<div class="display-flex-space-between">
					<div class="display-flex gap-s">
						<Switcher
							v-model="type"
							size="small"
							:data="[
								{
									multilingual_label: 'alerts.assets',
									value: 'assets'
								},
								{
									multilingual_label: 'alerts.energy_systems',
									value: 'systems'
								}
							]"
						/>

						<!-- Inser input into a form for disabling browser autocomplete -->
						<form autocomplete="off">
							<TextInput
								v-model="filter_text"
								:placeholder="
									type == 'assets'
										? $t('general.name') + '/' + $t('entities.asset.custom_id')
										: $t('general.name') +
										  '/' +
										  $t(
												'entities.energy_system.provider_data.system_id_in_provider'
										  )
								"
								:debounce="true"
								style="width: 300px"
								:disable_autocomplete="true"
							/>
						</form>

						<button
							class="btn btn-primary"
							v-on:click="show_filters = !show_filters"
						>
							<Icon icon="filter" />
						</button>
					</div>

					<div class="display-flex gap-s">
						<div
							v-if="
								type == 'assets' &&
								assets_group_assets_summary &&
								assets_group_assets_summary.length > 0
							"
							class="flex-vertical-center font-700"
						>
							<span class="grey1-color">
								{{ $t("dashboard.total_assets") }}:
								{{ assets_group_assets_summary.length }}
							</span>
						</div>
						<div
							v-else-if="
								type == 'systems' &&
								assets_group_systems_summary &&
								assets_group_systems_summary.length > 0
							"
							class="flex-vertical-center font-700"
						>
							<span class="grey1-color">
								{{ $t("dashboard.total_systems") }}:
								{{ assets_group_systems_summary.length }}
							</span>
						</div>

						<div
							v-if="
								type == 'assets' &&
								assets_group_assets_summary &&
								assets_group_assets_summary.length > 0
							"
						>
							<Tooltip
								position="bottom"
								:tooltip_text="$t('general.export')"
								:single_line_tooltip_text="true"
							>
								<template #content>
									<ExportAssetsGroupAssetsSummaryButton
										:assets_group_assets_summary="assets_group_assets_summary"
									/>
								</template>
							</Tooltip>
						</div>

						<router-link
							v-if="type == 'assets' && user_can_add_asset"
							:to="{ name: 'new-asset' }"
						>
							<button class="btn btn-primary full-height">
								<div class="display-flex">
									<span>{{ $t("general.add_new") }}</span>
									<Icon class="p-l-xs" size="12" icon="plus" />
								</div>
							</button>
						</router-link>

						<div class="full-height border-l"></div>

						<div>
							<Tooltip
								position="bottom"
								:tooltip_text="$t('general.update')"
								:single_line_tooltip_text="true"
							>
								<template #content>
									<button
										class="btn btn-primary full-height"
										v-on:click="reload_data"
									>
										<Icon size="18" icon="sync-alt" />
									</button>
								</template>
							</Tooltip>
						</div>
					</div>
				</div>

				<AssetsGroupFilters
					v-if="show_filters"
					:filters_type="type"
					v-on:filter="set_custom_filters"
				/>
			</div>
			<div class="content" v-if="show_data">
				<AssetsGroupAssets
					v-if="type == 'assets'"
					assets_group_id="default"
					:filter_text="filter_text"
					:custom_filters="custom_filters"
					:force_data_download="force_data_download"
					v-on:assets_group_assets_summary_loaded="
						assets_group_assets_summary_loaded
					"
				/>
				<AssetsGroupSystems
					v-else-if="type == 'systems'"
					assets_group_id="default"
					:filter_text="filter_text"
					:custom_filters="custom_filters"
					:force_data_download="force_data_download"
					v-on:assets_group_systems_summary_loaded="
						assets_group_systems_summary_loaded
					"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Switcher from "../../sub_components/Switcher.vue";
import TextInput from "@form_components/TextInput.vue";
import AssetsGroupFilters from "./AssetsGroupFilters.vue";

import ExportAssetsGroupAssetsSummaryButton from "./assets-group-assets/ExportAssetsGroupAssetsSummaryButton.vue";
import AssetsGroupAssets from "./assets-group-assets/AssetsGroupAssets.vue";
import AssetsGroupSystems from "./assets-group-systems/AssetsGroupSystems.vue";

import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util.js";
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "AssetsGroupContent",
	components: {
		Switcher,
		TextInput,
		AssetsGroupFilters,
		ExportAssetsGroupAssetsSummaryButton,
		AssetsGroupAssets,
		AssetsGroupSystems
	},
	data() {
		return {
			type: "assets", // assets | systems
			show_data: true,
			force_data_download: false,
			filter_text: null,
			assets_group_assets_summary: null,
			assets_group_systems_summary: null,
			user_can_add_asset: UserPermissionsUtil.can_user("create_asset"),
			show_filters: false,
			custom_filters: null
		};
	},
	methods: {
		assets_group_assets_summary_loaded(data) {
			this.assets_group_assets_summary = data;
		},
		assets_group_systems_summary_loaded(data) {
			this.assets_group_systems_summary = data;
		},
		reload_data() {
			this.force_data_download = true;

			setTimeout(() => {
				this.force_data_download = false;
			}, 500);
		},
		set_custom_filters(filter) {
			this.custom_filters = filter;
		}
	}
};
</script>
